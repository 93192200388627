<template>
    <section id="card-navigation">

      <b-row>
    
        <b-col>
          <b-card>
            <b-tabs v-model="tabIndex" nav-class="mb-3">
              <b-tab
                title="Account"
              >

                <b-form action="https://pay.testudy.io/create-checkout-session" method="POST" target="_blank"
                  v-if="!userData.is_premium" class="mb-2"
                >
                 <h3 class="mt-0 mb-1">Upgrade your account for full Testudy experience</h3> 

                <!-- Add a hidden field with the lookup_key of your Price -->
                  <!-- <input type="hidden" name="lookup_key" value="testudypayment" /> -->
                  <input type="hidden" name="client_reference_id" :value="userData.id">
                  <input type="hidden" name="customer_email" :value="userData.email">
                  <input type="hidden" name="name" :value="userData.name">
                  <input type="hidden" name="planselected" :value="planSelected">
                  
                  <b-form-group 
                    label="Select plan"
                    label-for="selectplan"
                    style="display: inline-block;"
                    class="mr-1">
                      <b-form-select id="selectplan" v-model="planSelected" :options="subscriptionsOptions" class="" style="margin-right: 10px;"></b-form-select>
                  </b-form-group>


                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
                    type="submit"
                    class="ml-0"
                  >
                  Upgrade
                  </b-button>	                  
                </b-form>


                <dl class="row">
                    <dt class="col-sm-3">
                        <b>Name</b>
                    </dt>
                    <dd class="col-sm-9">
                        {{ userData.name }}
                    </dd>
                </dl>
                <dl class="row">
                    <dt class="col-sm-3">
                        <b>Email</b>
                    </dt>
                    <dd class="col-sm-9">
                        <a :href="`mailto: ${userData.email}`">{{ userData.email }}</a>
                    </dd>
                </dl>
                <dl class="row">
                    <dt class="col-sm-3">
                        <b>Tariff plan</b>
                    </dt>
                    <dd class="col-sm-9">
                        <span v-if="userData.is_premium" class="text-success">{{ userData.subscription_name }}</span> 
                        <span v-else class="text-danger">Limited</span> 
                    </dd>
                </dl>
                <dl class="row">
                    <dt class="col-sm-3">
                        <b>Tests created</b>
                    </dt>
                    <dd class="col-sm-9">
                        <span>{{ userData.questions_created }}</span> 
                        <span v-if="!userData.is_premium"> / {{ freeAccountLimit }}</span> 
                        <span v-else> / ∞</span> 
                    </dd>
                </dl>       
                <dl class="row">
                    <dt class="col-sm-3">
                        <b>Socrates spent characters</b>
                    </dt>
                    <dd class="col-sm-9">
                        <span>{{ userData.symbols_free_alg }}</span> 
                        <span v-if="userData.char_limit_alg_free > 0"> / {{ userData.char_limit_alg_free }}</span> 
                        <span v-else> / ∞</span> 
                    </dd>
                </dl>     
                <dl class="row">
                    <dt class="col-sm-3">
                        <b>Plato spent characters</b>
                    </dt>
                    <dd class="col-sm-9">
                        <span>{{ userData.symbols_paid_alg }}</span> 
                        <span v-if="userData.char_limit_alg_paid > 0"> / {{ userData.char_limit_alg_paid }}</span> 
                        <span v-else> / ∞</span> 
                    </dd>
                </dl>                                         
                <dl class="row" v-if="userData.is_premium">
                    <dt class="col-sm-3">
                        <b>Subscription period ending</b>
                    </dt>
                    <dd class="col-sm-9">
                        {{ userData.subscription_period_end | formatDate }}
                    </dd>
                </dl>
                <dl class="row">
                  <dt class="col-sm-12">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-secondary"
                      class=""
                      href="/forgot-password"
                      target="_blank"
                    >                      
                      Reset password
                    </b-button>
                  </dt>
                </dl>



                


              </b-tab>
              <b-tab title="Billing">
                <h4 class="card-title mb-3">Billing History</h4>
                <p v-if="totalRows === 0 && isBillingLoaded">There are no items in your billing history yet</p>
                <p v-if="!isBillingLoaded">
                  <b-spinner
                    class="mr-1"
                    variant="info"
                  />
                  Loading...
                </p>
                <app-timeline>
                  <app-timeline-item
                    :variant="colorByTrType(item.ev_type)"
                    :icon="iconByTrType(item.ev_type)"
                    v-for="item in items" 
                    :key="item.id"
                  >
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0" >
                      <h6><b>{{ item.ev_type_name }}</b></h6>
                      <small class="text-muted">{{ item.ev_created | formatDate }}</small>
                    </div>
                    <div class="d-flex flex-sm-row flex-column justify-content-between align-items-start">

                      <div class="mb-1 mb-sm-0">
                        <span class="text-muted mb-50 d-block">Payment System</span>
                        <span>{{ item.payment_system_name }}</span>
                      </div>

                      <div class="mb-1 mb-sm-0" v-if="item.ev_type === 1">
                        <span class="text-muted mb-50 d-block">Period</span>
                        <span><b>{{ item.ev_period_start | formatDate }} - {{ item.ev_period_end | formatDate }}</b></span>
                      </div>

                      <div v-if="item.ev_type === 2">
                        <span class="text-muted mb-50 d-block">Invoice</span>
                        <b-button 
                          v-b-toggle.report-list-with-icon
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-success"
                          :href="item.ev_invoice_url"
                          target="_blank"
                          class="mr-1 mb-1"
                        >
                        <feather-icon
                          icon="LinkIcon"
                            class="mr-50"
                        />                        
                          View
                        </b-button>
                        <b-button 
                          v-b-toggle.report-list-with-icon
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          size="sm"
                          variant="outline-success"
                          :href="item.ev_invoice_pdf"
                          class="mb-1"
                        >
                        <feather-icon
                          icon="ArrowDownCircleIcon"
                            class="mr-50"
                        />                        
                          Download
                        </b-button>
                      </div>

                      <div>
                        <span v-if="item.ev_type === 2" class="text-muted mb-50 d-block">Amount</span>
                        <span v-else class="text-transparent mb-50 d-block">Amount</span>
                        <span v-if="item.ev_type === 2"><b>€ {{ item.ev_amount_total | formatCentsToEuro }}</b></span>
                      </div>                      

                    </div>
                  </app-timeline-item>
                </app-timeline>
                <b-card>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    v-if="!itemsOpenedAll && totalRows > 0"
                    @click="loadAllTransactions"
                  >
                    <span class="align-middle">View all</span>
                    <feather-icon
                      icon="ArrowDownIcon"
                      class="ml-50"
                    />
                  </b-button>	
                </b-card>  
              </b-tab>
            </b-tabs>
          </b-card>
          <b-card 
            title="Subscription management"
            v-if="userData.subscription_period_end"
            >
            <b-card-text>
              <p>You can manage your subscription:</p>
              <ul>   
                <li>Cancel subscription</li>
                <li>Change or add payment method</li>
                <li>Change billing email</li>
                <li>View billing history (invoices and receiptes)</li>
              </ul>

              <b-form action="https://pay.testudy.io/create-portal-session" method="POST" target="_blank"
                >
                  <input type="hidden" name="stripe_customer_id" :value="userData.stripe_customer_id">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                    class="mt-1"
                  >
                    <feather-icon icon="AwardIcon" class="mr-50" />                   
                    Manage your subscription
                  </b-button>	                  
                </b-form>

            </b-card-text>            
          </b-card>

        </b-col>
      </b-row>

    <b-modal
      ref="modal-form-success"
      centered
      title="Payment success"
      modal-class="modal-success"
      ok-only
      ok-title="Ok"
      ok-variant="success"
      @hide="clearUrlParam"
    >
      <div class="d-block text-center">
        <h3>Thank you! You are subscribed now!</h3>
      </div>
     
    </b-modal>


    </section>
  </template>
  
  <script>
  import {
    BRow, BCol, BCard, BTab, BTabs, BCardText, BCardTitle, BButton, BForm, BFormInput, BFormGroup, BImg, BModal, VBToggle, BSpinner, BFormSelect
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'  
  // import NavBar from '@/layouts/components/Navbar.vue' 
  import Ripple from 'vue-ripple-directive'
  // import store from '@/store'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  

  export default {
    components: {
      BRow, BCol, BCard, BTab, BTabs, BCardText, BCardTitle, BButton, BForm, BFormInput, BFormGroup, BImg, BModal, AppTimeline, AppTimelineItem, BSpinner, BFormSelect
      // , NavBar
      // , store
    },
    directives: {
      Ripple,
      'b-toggle': VBToggle,
    },
    data() {
        return {
            freeAccountLimit: 200,
            userData: JSON.parse(localStorage.getItem('userData')),
            items: [],
            itemsOpenedAll: false,
            totalRows: 0,
            tabIndex: 0,
            paymentShow: false,
            isBillingLoaded: false,
            planSelected: '0',
            subscriptionsOptions: [],
            }
        },  
    methods: {
      logout() {
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
        localStorage.removeItem('userData')
        this.$router.push({ name: 'auth-login' })      
      },		      
      getUserProfile() {
        useJwt.getUserProfile({
          // src_text: this.textQuiz,
        })
        .then(response => {
          const resData = response.data
          // console.log("response.data:")
          // console.log(response.data)
          // this.items = resData
          // Set the initial number of items
          

          this.userData.id = resData[0].id;
          this.userData.name = resData[0].name;
          this.userData.credits = resData[0].credits;
          this.userData.email = resData[0].email;
          this.userData.is_premium = resData[0].is_premium;
          this.userData.subscription_period_end = resData[0].subscription_period_end;
          this.userData.stripe_customer_id = resData[0].stripe_customer_id; 
          this.userData.questions_created = resData[0].questions_created; 

          this.userData.symbols_free_alg = resData[0].symbols_free_alg; 
          this.userData.symbols_paid_alg = resData[0].symbols_paid_alg; 
          this.userData.char_limit_alg_free = resData[0].char_limit_alg_free; 
          this.userData.char_limit_alg_paid = resData[0].char_limit_alg_paid; 
          this.userData.subscription_name = resData[0].subscription_name; 
          


          localStorage.setItem('userData', JSON.stringify(this.userData))
          // document.getElementById('nb-username').innerHTML =this.userData.name;
          this.updateNavbarProfile();
          // NavBar.userData = this.userData;
          // window.location.reload(true);
          // NavBar.forceReload += 1;
          // store.state.appConfig.globalUserData = userData;
          // console.log(this.$store.appConfig.globalUserData);
          // console.log(store.state.appConfig.isRTL)
        })
        .catch(error => {
          //this.$refs.loginForm.setErrors(error.data);
          // console.log(error)
          if (error.status == 401) {
            this.logout();
          } else {            
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting User Profile",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.data.email,
                },
              }) 
          }     
        }) 
        .finally(()=>{ 
          // this.seenTable = true        
        });
      },  

      loadTopTransactions() {
        this.isBillingLoaded = false;
        useJwt.getTopTransactions({
          // src_text: this.textQuiz,
        })
        .then(response => {
          const resData = response.data
          this.items = resData
          this.totalRows = this.items.length
          // console.log(this.items);
          // this.totalRows = this.items.length
        })
        .catch(error => {
          if (error.status == 401) {
            this.logout();
          } else {  
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting transactions",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.data.email,
                },
              })      
          }    
        }) 
        .finally(()=>{ 
          // this.seenTable = true  
          this.isBillingLoaded = true;      
        });
      },

      loadAllTransactions() {
        this.isBillingLoaded = false;
        useJwt.getAllTransactions({
          // src_text: this.textQuiz,
        })
        .then(response => {
          const resData = response.data
          this.items = resData
          this.totalRows = this.items.length
          this.itemsOpenedAll = true;
          // console.log(this.items);
          // this.totalRows = this.items.length
        })
        .catch(error => {
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting transactions",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.data.email,
                },
              })      
        }) 
        .finally(()=>{ 
          // this.seenTable = true  
          this.isBillingLoaded = true;      
        });
      },


      loadSubscriptions() {
        useJwt.getSubscriptions({
          // src_text: this.textQuiz,
        })
        .then(response => {
          this.subscriptions = response.data;
          for (var index in this.subscriptions) {
            this.subscriptionsOptions.push({'value': this.subscriptions[index].id, 'text': this.subscriptions[index].name + ' (€' + this.subscriptions[index].price + ')'});
          }

          if (this.$route.params.planIndex) {
            this.planSelected = this.subscriptions[this.$route.params.planIndex-1].id;
          } else  
            // this.planSelected = this.subscriptions[this.subscriptions.length-1].id;          
            this.planSelected = 2;          
        
        })
        .catch(error => {
          this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: "Error getting subscriptions",
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  // text: error.data.email,
                  text: error.data.email,
                },
              })      
        }) 
        .finally(()=>{ 
          //
        });
      },      

      iconByTrType(ev_type) {
        switch (ev_type) {
          case 0: 
            return 'UserCheckIcon';
          case 1: 
            return 'AwardIcon';
          case 2: 
            return 'DollarSignIcon';
          default:
            return 'ClipboardIcon';          
        }
      },
      colorByTrType(ev_type) {
        switch (ev_type) {
          case 0: 
            return 'warning';
          case 1: 
            return 'primary';
          case 2: 
            return 'success';
          default:
            return 'primary';          
        }
      },

      
      updateNavbarProfile() {
        document.getElementById('nb-username').innerHTML = this.userData.name;
        var ps =  document.getElementById('profile-state').getElementsByClassName('b-avatar-badge')[0];
        if (this.userData.is_premium) {
          ps.classList.remove('badge-secondary');
          ps.classList.add('badge-success');
        } else {
          ps.classList.remove('badge-success');          
          ps.classList.add('badge-secondary');
        }
      },

      showPaymentSuccess() {
        this.$refs['modal-form-success'].show();
      },

      clearUrlParam() {
        this.$router.push({ name: 'pages-profile' })
      }

    }, 
    
    
    created() {
      if (this.$route.query.tab) {
        this.tabIndex = parseInt(this.$route.query.tab);
      }
    },

    mounted() {
      this.getUserProfile();
      this.loadTopTransactions();
      
      if (this.$route.query.status === 'success') {
          this.showPaymentSuccess();
        } 
      
      this.loadSubscriptions();
      // this.totalRows = this.items.length
    // console.log(this.totalRows)

    },  
  
  
  }
  </script>
  
